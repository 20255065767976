import React from "react"
import { graphql } from "gatsby"
import BlogLayout from "../components/blog/blogLayout"
import * as styles from "../styles/blog.module.css"
import { StaticImage } from "gatsby-plugin-image"

const BlogDetails = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <BlogLayout data={frontmatter}>
      <div
        className={
          styles.contentArea +
          // ` parallax4 h-100 w-100 d-flex flex-row justify-content-center ` +
          ` parallax4 h-100 w-100 d-flex justify-content-center ` +
          styles.itemContentArea
        }
        // style={{ width: "1145px" }}
      >
        <div
          className="w-fit icon mx-0 position-absolute"
          // style={{ marginTop: "-89px", zIndex: "500" }}
          style={{ marginTop: "-48px", zIndex: "500" }}
        >
          <StaticImage
            src="../../images/profile/chip.png"
            alt="Chip"
            placeholder="blurred"
            width={145}
            height={145}
          />
        </div>
        <div
          className={`bg-transparent col position-relative mx-0 mx-md-3 p-0 w-100 mt-md-4 mb-5`}
          style={{ maxWidth: "1000px" }}
        >
          <div
            className={styles.cardDiv + ` card shadow-sm bg-transparent`}
            style={{
              marginLeft: "60px",
              marginRight: "60px",
            }}
          >
            <div style={{ display: "grid" }}>
              <div
                style={{
                  gridArea: "1/1",
                  position: "relative",
                  display: "grid",
                }}
                className={styles.bgLayer}
              ></div>

              <div
                className={styles.cardBody + ` card-body m-0 p-2 w-100`}
                style={{
                  gridArea: "1/1",
                  position: "relative",
                  display: "grid",
                }}
              >
                <div className={styles.descBox + ` p-md-4 d-flex`}>
                  <div className="m-0 w-100 mb-2 pt-0 pb-2" id="html">
                    <div
                      className="mdHtml"
                      id="blogMdHtml"
                      style={{ maxWidth: "" }}
                      dangerouslySetInnerHTML={{
                        __html: html,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlogLayout>
  )
}

export default BlogDetails

export const query = graphql`
  query MyQuery2($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        desc
      }
      html
    }
  }
`
